const logotext = "NIRMAL";
const meta = {
    title: "nirmal Kumar",
    description: "I’m Nirmal Kumar Java Full stack devloper,currently working in Gurgoan,Haryana",
    image:"nirmalsharma-in.webp"
};

const introdata = {
    title: "I’m Nirmal Kumar",
    animated: {
        first: "The best way to predict the future is to implement it.",
        second: "Coding is not just about writing code, it's about creating a solution.",
        third: "The strength of Java is that it's very readable.",
    },
    description: "Experienced Java Developer with a proven track record of 5+ years in designing, developing, and deploying dynamic software applications.",
    your_img_url: "nirmalsharma-in.png",
};

const dataabout = {
    title: "abit about my self",
    aboutme: "Experienced Java Developer with a proven track record of 4+ years in designing, developing, and deploying dynamic software applications. Proficient in Java/J2EE technologies, Spring MVC/Boot, Hibernate, and Design Patterns. Skilled in full-stack development, including JavaScript, HTML, and JSON. Known for effective team leadership, Agile methodologies, and a client-centric approach. Passionate about continuous learning and staying abreast of emerging trends. Seeking a challenging opportunity to leverage technical expertise and leadership acumen in contributing to organizational success and innovation.",
};
const worktimeline = [{
        jobtitle: "Associate Projects (FSE)",
        where: "Cognizant",
        date: "2022",
        website:"https://www.cognizant.com/in/en"
    },
    {
        jobtitle: "Java Developer",
        where: "Tata nexarc",
        date: "2021",
        website:"https://www.tatanexarc.com/"
    },
    {
        jobtitle: "Software Engineer",
        where: "Liberin Technologies Private Limited",
        date: "2019",
        website:"https://liberintechnologies.com/"
    },
];

const skills = [{
        name: "Java",
        value: 90,
    },
    {
        name: "Spring MVC/Boot",
        value: 85,
    },
    {
        name: "Microservices (Spring Cloud)",
        value: 80,
    },
    {
        name: "React",
        value: 80,
    },
    {
        name: "SQL",
        value: 85,
    },
];

const services = [{
        title: "Backend Developer ",
        description: "Responsible for designing, developing, and maintaining server-side applications using Java technologies. Collaborated with cross-functional teams to ensure seamless integration, performance optimization, and implementation of security measures.",
    },
    {
        title: "Frontend Developer",
        description: "As a React Frontend Developer, I specialize in crafting responsive user interfaces and collaborating with cross-functional teams to implement cutting-edge features, optimizing performance and user experience.",
    },
];

const dataportfolio = [{
        img: "bmw_group_logo.png",
        description: "Global Credit Assessment Platform (GCAP) is a system for processing Car lease Loan application for BMW Customers.",
        link: "https://www.bmw.in/en/fastlane/bmw-financial-services-new/overview.html",
    },
    {
        img: "tatanexarc.png",
        description: "Tata nexarc is a B2B platform that provides trusted and affordable solutions to accelerate growth for MSMEs.",
        link: "https://www.tatanexarc.com/",
    },
    {
        img: "bdpost.svg",
        description: " Collaborated on the development of the Bangladesh Postal Service logistics web application for Client Pitney Bowes.",
        link: "https://www.pitneybowes.com/in",
    },
    {
        img: "cardbyte.png",
        description: "Contributed to the development of CardByte, a digital business card management application.",
        link: "https://web.cardbyte.ai/login",
    },
    {
        img: "planify.jpg",
        description: "Planify is the biggest platform to invest in Startups, MSMEs, Pre-IPO & Unicorns and connects investors with entrepreneurs for Angel Investing.",
        link: "https://www.planify.in/",
    },
];

const contactConfig = {
    YOUR_EMAIL: "contacts@nirmalsharma.in",
    YOUR_FONE: "+91 9319102623",
    description: "Gurgoan,Haryana,India - 122002",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_id",
    YOUR_TEMPLATE_ID: "template_id",
    YOUR_USER_ID: "user_id",
};

const socialprofils = {
    github: "https://github.com/nirmal-sharma-it",
    facebook: "https://www.facebook.com/nirmal.sharmaaa",
    linkedin: "https://in.linkedin.com/in/nirmal-sharmaa",
    twitter: "#",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};